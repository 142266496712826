import React, { Component } from 'react';
import './Header.scss';
import globalData from '../../json/global';
import { Link} from 'react-router-dom';

class Header extends Component {

    componentDidMount() {
        window.addEventListener('scroll', this.constructor.handleScroll);

        // fix toggle to right of viewport
        this.constructor.handleTogglePosition();
        window.onresize = this.constructor.handleTogglePosition;
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.constructor.handleScroll);
    }

    static handleTogglePosition() {

        const toggle = document.getElementById('toggle');
        const offset = (window.innerWidth - toggle.parentElement.offsetWidth) / 2;

        toggle.style.right = `${offset}px`;

    }

    static handleScroll() {
        const header = document.getElementById('header');
        const scrolledClass = 'header--scrolled';

        if (window.scrollY > 100) {
            header.classList.add(scrolledClass);
        } else {
            header.classList.remove(scrolledClass);
        }
    }

    render() {
        return (
            <header className="header" id="header" onScroll={this.constructor.handleScroll}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between">
                                <Link className="header__title"
                                      to="/"
                                      aria-label="Home">
                                    <span>Jase<span></span>Warner </span>
                                    <span>{globalData.siteDescription}</span></Link>
                                <button className="header__toggle capitalised"
                                        id="toggle"
                                        onClick={this.props.toggleNav}
                                        aria-label="Toggle menu"
                                        type="button">
                                    <span className="toggle-title">Menu</span>
                                    <span className="toggle-box">
                                        <span className="toggle-line"></span>
                                        <span className="toggle-line"></span>
                                        <span className="toggle-line"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header;
