import React, { Component } from 'react';

class PageBreak extends Component {
	render() {
		return (
			<div className="container-fluid">
				<div className="row justify-content-center">
					<div className="col-12 col-sm-10 col-lg-8">
						<hr/>
					</div>
				</div>
			</div>
		)
	}
}

export default PageBreak;
