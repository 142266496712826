import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';
import ProjectText from "../../components/Project/ProjectText";

class HondaCartagena extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.hondaCartagena;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'This track uses synths, samples, drums, guitar, and field recordings from my trip to Colombia in 2018.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="Field recordings, synths, samples and guitars"
					/>

					<ProjectText
						text={`<p>This track uses synths, samples, guitars, drums, and <a href="https://soundcloud.com/jasewarner/sets/mexico-and-colombia-field-recordings" target="_blank" rel="noopener noreferrer">field recordings</a> from my trip to Colombia in early 2018. The piece is broken into two parts: afternoon and evening.</p>`}
					/>

					<ProjectTrack
						title="Honda/Cartagena"
						id="485806581"
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.bharatJean}
						next={projectsData.projects.wordpressInstaller}
					/>
				</article>
			</>
		)
	}
}

export default HondaCartagena;
