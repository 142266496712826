import React, { Component } from 'react';
import Helmet from 'react-helmet-async';
import projectsData from '../../json/projects';
import PageHeader from '../../components/PageHeader/PageHeader';
import ProjectText from '../../components/Project/ProjectText';
import ProjectImage from '../../components/Project/ProjectImage';
import BackHome from '../../components/BackHome/BackHome';
import ProjectFooter from '../../components/Project/ProjectFooter';

class Grato extends Component {

    constructor(props) {
        super(props);

        this.project = projectsData.projects.grato;

        this.pageTitle = `${this.project.title} – Jase Warner`;
        this.pageUrl = `https://jase.io/${this.project.slug}`;
        this.pageDescription = 'Website design for family-owned flooring business';
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta property="og:title" content={this.pageTitle} />
                    <meta property="og:description" content={this.pageDescription} />
                    <meta property="og:url" content={this.pageUrl} />
                </Helmet>
                <article>
                    <PageHeader
                        title={this.project.title}
                        tags={this.project.tags}
                        description={this.pageDescription}
                    />

                    <ProjectText
                        text={`<p>Grato is a family-owned flooring business based in northern Spain. Known for crafting beautiful hardwood floors, stairs and paneling, the team at Grato were in need of a completely new direction for their website.</p><p>I was hired by Grato to provide designs for their <a href="https://grato.es/" target="_blank" rel="noopener noreferrer">new website</a>, including handover instructions for their web development team who later developed the site.</p>`}
                    />

                    <ProjectImage
                        src={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02.jpg`}
                        src2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02@2x.jpg`}
                    />

                    <BackHome/>

                    <ProjectFooter
                        prev={projectsData.projects.mashu}
                        next={projectsData.projects.aLW}
                    />
                </article>
            </>
        )
    }
}

export default Grato;
