import React, { Component } from 'react';
import './Project.scss';

class ProjectImage extends Component {
    render() {
        return (
            <div className="project-image project-content">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                            <figure>
                                <img className="img-fluid mb-3 mb-sm-5"
                                     alt={this.props.alt}
                                     width={this.props.width}
                                     height={this.props.height}
                                     srcSet={`${this.props.src} 1x, ${this.props.src2x} 2x`}
                                     src={this.props.src} />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProjectImage;
