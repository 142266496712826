import React, { Component } from 'react';
import Loader from 'react-loaders';
import './LoadingScreen.scss';

class LoadingScreen extends Component {
	render() {
		return (
			<div className={`loading-screen ${this.props.hidingLoadingScreen ? `loading-screen--hiding` : ``} ${this.props.hideLoadingScreen ? `loading-screen--hide` : ``}`}>
				<p className="loading-screen__name">jase_warner</p>
				<Loader type="pacman" />
			</div>
		)
	}
}

export default LoadingScreen;
