import React, { Component } from 'react';
import './Player.scss';
import Slider from 'react-slick';
import globalData from '../../json/global';

class Player extends Component {

    constructor(props) {
        super(props);

        this.tracksArr = [];
        this.iframeStart = 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/';
        this.iframeEnd = '&color=%23f3683d&inverse=true&auto_play=false&show_user=true';
        this.settings = {
            dots: false,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear',
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        this.setTracksArray = this.setTracksArray.bind(this);
        this.afterChangeHandler = this.afterChangeHandler.bind(this);
    }

    setTracksArray() {
        globalData.player.tracks.map((item) => {
            return this.tracksArr.push(`${this.iframeStart}${item.id}${this.iframeEnd}`);
        });
    }

    afterChangeHandler(currentSlide) {

        const iframes = document.getElementsByClassName('player__iframe');

        Array.from(iframes).forEach((item, index) => {
            item.removeAttribute('src');
        });

        iframes[currentSlide].setAttribute('src', this.tracksArr[currentSlide]);
    }

    render() {
        // store tracks in an array
        let tracksArr = [];
        globalData.player.tracks.map((item) => {
            return tracksArr.push(item.id);
        });

        return (
            <div className="player">
                <div className="player__inner">
                    <div className="player__slider">
                        <Slider {...this.settings} onInit={this.setTracksArray} afterChange={this.afterChangeHandler}>
                        {globalData.player.tracks.map((item) => {
                            return (
                                <div key={item.id}>
                                    <iframe className="player__iframe"
                                            id={`player${item.id}`}
                                            width="100%"
                                            height="20"
                                            scrolling="no"
                                            frameBorder="no"
                                            title="Jase Warner on Soundcloud"
                                            src={`${this.iframeStart}${item.id}${this.iframeEnd}`}></iframe>
                                </div>
                            )
                        })}
                        </Slider>
                    </div>
                    <button onClick={this.props.togglePlayer}
                            className="player__hide capitalised"
                            type="button">Hide</button>
                </div>
            </div>
        )
    }
}

export default Player;
