import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import SpotifyEmbed from '../../components/Project/SpotifyEmbed';
import AppleMusicEmbed from '../../components/Project/AppleMusicEmbed';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';
import ProjectImage from "../../components/Project/ProjectImage";
import ProjectText from "../../components/Project/ProjectText";
import ProjectTrack from "../../components/Project/ProjectTrack";

class Hypnagogia extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.hypnagogia;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'A composition built around a field recording of the ocean surging against sea wall rocks in South Australia.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description={`A composition built around a field recording of the ocean surging against sea wall rocks in South Australia.`}
					/>

					<ProjectText
						text={`<p>This track uses a field recording by Alan Cook of waves crashing into a sea wall in Port Noarlunga. The original recording has been manipulated in various ways and is joined by synthesisers.</p>`}
					/>

					<ProjectTrack
						title="Hypnagogia"
						id="1592932155"
					/>

					<SpotifyEmbed
						src="https://open.spotify.com/embed/track/3NuEJZcwa9qhv0X0RNdcjS?utm_source=generator&theme=0"
						title="Hypnagogia"
					/>

					<AppleMusicEmbed
						src="https://embed.music.apple.com/gb/album/hypnagogia-single/1704114170"
						title="Hypnagogia"
					/>

					<ProjectImage
						src={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02.jpg`}
						src2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02@2x.jpg`}
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.kittyCooper}
						next={projectsData.projects.hooverDirect}
					/>
				</article>
			</>
		)
	}
}

export default Hypnagogia;
