import React, { Component } from 'react';
import './404.scss';

class comp404 extends Component {
	render() {
		return (
			<div className="comp404">
				<div className="comp404__video">
					<video autoPlay playsinline loop muted controls={false}>
						<source src={`/assets/videos/404/404.mp4`} type="video/mp4"/>
						<source src={`/assets/videos/404/404.ogv`} type="video/ogv"/>
						Your browser does not support the video tag.
					</video>
				</div>
				<article className="comp404__text">
					<h1>404</h1>
					<p>Big trouble!
						<br/>Page not found.</p>
				</article>
			</div>
		)
	}
}

export default comp404;
