import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';
import ProjectText from "../../components/Project/ProjectText";

class Avignon extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.avignon;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'I recently travelled to the South of France, where I took the opportunity to record the sounds of the surrounding countryside, as well as our various visits into Avignon and Arles.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="I recently travelled to the South of France, where I took the opportunity to record the sounds of the surrounding countryside, as well as our various visits into Avignon and Arles."
					/>

					<ProjectTrack
						title="Avignon Field Recordings"
						id="8876817"
						playlist
					/>

					<ProjectText
						text={`<p>Following on from my last collection of <a href="/${projectsData.projects.venice.slug}">field recordings taken from a trip to Venice</a>, this selection features the ever present cicadas and crickets (which can be heard almost everywhere in southern France).</p>
							<p>As well as the insects, you will also hear the sound of a courtyard and public square in Arles, where we visited the city’s photography exhibition. The soundtrack of a busy public square in Avignon is also featured in the recordings, as well as the pulsating soundtrack of the train station. So, in short, lots of crickets, cicadas, hubbub and sunshine.</p>`}
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.paris}
						next={projectsData.projects.venice}
					/>
				</article>
			</>
		)
	}
}

export default Avignon;
