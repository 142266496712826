import React, { Component } from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import PageText from '../../components/PageText/PageText';
import PageBreak from '../../components/PageBreak/PageBreak';
import SkillsTags from '../../components/SkillsTags/SkillsTags';
import Clients from '../../components/Clients/Clients';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';
import pagesData from '../../json/pages';

class About extends Component {

    constructor(props) {
        super(props);

        this.pageUrl = `https://jase.io/${pagesData.about.slug}`;
        this.pageTitle = `${pagesData.about.title} – Jase Warner`;
        this.pageDescription = 'I am a freelance creative front-end developer based in London. I specialise in designing and developing Shopify and WordPress themes.';
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
                <article className="mb-10 mb-lg-20 text-center">
                    <PageHeader title="Who, me?" />
                    <PageText
                        text={`<p class="lead">I am a freelance front-end developer based in London. <br/>I love code, design, and sounds.</p>
                            <p>I specialise in building <a href="https://www.shopify.com/?ref=j-m-warner" target="_blank" rel="noreferrer noopener">Shopify</a> and WordPress themes from the ground up. My skill set also includes Gatsby, React, JavaScript, BEM, Gulp, HTML5, CSS3 and SCSS.</p>
                            <p>In 2023, I was thrilled to win the YunoJuno <a href="https://www.thefreelancerawards.com/sweetfa23" target="_blank" rel="noreferrer noopener">freelancer of the year award</a> for web development. I was also shortlisted as a finalist for the same award in 2021.</p>
                            <p>Since switching from full-time to freelance in 2014, I have worked on a variety of front-end and full-stack contracts in London. If you’d like to, you can <a href="https://uk.yunojuno.com/ref/c/n3ynrh/" target="_blank" rel="noreferrer noopener">book me today</a>. I pride myself on writing tidy and robust code, and working in a collaborative environment suits me to a T.</p>
                            <p>As well as my development and design work, I make ambient/electronic music, take field recordings of my surroundings and watch John Carpenter films.</p>`}
                    />
                    <PageBreak/>
                    <SkillsTags title="Skills" />
                    <PageBreak/>
                    <PageText
                        text={`<h2 class="h3 mb-4">Background</h2>
                            <p>I have been developing websites for over 20 years. I hold a BA Degree in Fine Art and studied Typography at University of the Arts London, Central Saint Martins.</p>
                            <p>As well as my development work, I enjoy creating ambient and electronic music. I have exhibited my sound work in London and Cardiff, and regularly contribute to the <a href="https://citiesandmemory.com/" target="_blank" rel="noreferrer noopener">Cities and Memory</a> field recording project.</p>
                            <p>I like designing and building digital things, making music, cricket, trying so very hard to speak French, watching John Carpenter films, reading, and cycling.</p>
                            <p>I believe Kurt Russell to be the best.</p>`}
                    />
                    <PageBreak/>
                    <Clients title="Clients" />
                    <PageBreak/>
                    <PageText
                        text={`<h2 class="h3 mb-4">Education / Training</h2>
                            <p><strong>Typography</strong>
                            <br>Central Saint Martins College of Art & Design,
                            <br>University of the Arts, London (2014)</p>
                            <p><strong>BA (Hons) Degree in Fine Art</strong>
                            <br>Cardiff Metropolitan University (2005-09)</p>`}
                    />

                    <BackHome/>
                </article>
            </>
        )
    }
}

export default About;
