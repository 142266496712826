import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';
import ProjectText from "../../components/Project/ProjectText";

class Venice extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.venice;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'I recently had the pleasure of visiting Venice. I enjoyed the sights and sounds of the city and visited the Biennale.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="I recently had the pleasure of visiting Venice. I enjoyed the sights and sounds of the city and visited the Biennale."
					/>

					<ProjectTrack
						title="Venice Field Recordings"
						id="6835710"
						playlist
					/>

					<ProjectText
						text={`<p>This trip was an ideal opportunity to do some field recording in a vibrant and yet tranquil environment.</p>
							<p>I heard the hustle and bustle of packed tourist-filled streets, the peace and quiet of the side streets, the engines of the boats, the push and pull of the tides, the roar of projectors in the Arsenale, the mechanics of various installations, as well as a military brass band playing in a particularly small boat.</p>`}
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.avignon}
						next={projectsData.projects.passengers}
					/>
				</article>
			</>
		)
	}
}

export default Venice;
