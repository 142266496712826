import React, { Component } from 'react';
import './SpotifyEmbed.scss';

class SpotifyEmbed extends Component {
	render() {
		return (
			<div className="spotify-embed">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-12 col-md-10">
							<div className="spotify-embed__content">
								<iframe
									src={this.props.src}
									title={this.props.title}
									width="100%"
									height="352"
									frameBorder="0"
									allowFullScreen=""
									allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
									loading="lazy"
								></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default SpotifyEmbed;
