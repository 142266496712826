import React, { Component } from 'react';
import './Toolbar.scss';

class Toolbar extends Component {
	render() {
		return (
			<div className="toolbar">
				<ul>
					<li>
						<button onClick={this.props.togglePlayer}
						        className="toolbar__btn toolbar__btn--player"
						        type="button"
						        aria-label="Listen">
							<span>Listen</span>
						</button>
					</li>
					<li>
						<button onClick={this.props.handleShowModal}
						        className="toolbar__btn toolbar__btn--contact"
						        type="button"
						        aria-label="Contact">
							<span>Contact</span>
						</button>
					</li>
				</ul>
			</div>
		)
	}
}

export default Toolbar;
