import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';
import ProjectText from "../../components/Project/ProjectText";

class Utopia extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.utopia;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'I recently took part in a Cities & Memory project where various sound artists and musicians re-imagined Thomas Moore’s Utopia.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="I recently took part in the Cities & Memory project, Utopia, where various sound artists and musicians re-imagined Thomas Moore’s work of the same name."
					/>

					<ProjectText
						text={`<p>Having recently read Utopia, I attempted to depict the idyllic society that features in Books 1 and 2 in a naturalistic soundscape, using my vast archive of field recordings as a resource. By keeping the use of effects to a minimum and only drawing upon original recordings, I aimed to create an organic soundscape that would reflect the primitive and peaceful world of the Utopians.</p>`}
					/>

					<ProjectTrack
						title="Utopia"
						id="225818342"
					/>

					<ProjectText
						text={`<p>Here are some words from Cities & Memory on <a href="http://citiesandmemory.com/" target="_blank" rel="noreferrer noopener">the project</a>:</p>
							<p>&ldquo;Using a woodcut map from the second edition of Utopia  from 1518, sound artists each took a small section of More’s imagined country of Utopia, and created a new soundscape imagining how that place (and the society living in it) might sound.</p>
							<p>The result is a collaborative sonic imagination of what More’s Utopia might have sounded like, and deals with issues including slavery, religion, freedom and even the current refugee crisis in Europe.</p>
							<p>The sounds range from musical tracks built out of field recordings from Italy, France, USA, Australia and many other locations;  drone and ambient pieces; pieces constructed from readings of the original text and other works; imagined conversations on the island; and even full-scale electronica tracks.&rdquo;</p>
							<p>With regards to my own composition, recordings from France feature, including the Jardin du Luxembourg in Paris, and an art exhibition courtyard in Arles, as well as some sounds from my home London: the bells of St Paul’s Cathedral, which ring aloud across the Thames at 7 a.m. on a cold wintery morning, and the sound of my old dishwasher mid-cycle – the frequencies having been tweaked to create a cacophony of high shimmers.</p>
							<p>When trimmed, tweaked and moved into place, these recordings begin to take on a life of their own. The sound of horse hoofs can be heard, as well as musicians playing some way down the street; passers-by chat amongst themselves, insects and birds sing their songs, and bells ring aloud far off in the distance, suggesting some kind of grand occasion. And then those high shimmers come to the fore to present what could be rain, or perhaps machinery, or even the cooking of street food.</p>`}
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.maxenceParache}
						next={projectsData.projects.isabelPradilla}
					/>
				</article>
			</>
		)
	}
}

export default Utopia;
