import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';
import ProjectText from "../../components/Project/ProjectText";

class OmegaCentauri extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.omegaCentauri;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Ambient composition';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="Ambient composition"
					/>

					<ProjectTrack
						title="Omega Centauri"
						id="138302402"
					/>

					<ProjectText
						text={`<p>Reaching out for something that is unknown. Feeling for a familiar grip in the darkness. Searching for the unexplored, for the creatures and objects that could change us.</p>
							<p>Entering the vastness of space and exploding with vibrations, echoes, sounds, noise, whirrs, clicks, stuttered delays and flickered snaps.</p>
							<p>Forever we are breathing and reaching out.</p>`}
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.spectrum}
						next={projectsData.projects.thomasHumeau}
					/>
				</article>
			</>
		)
	}
}

export default OmegaCentauri;
