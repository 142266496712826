import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTop extends Component {
	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.location.hash.length === 0 && this.props.location !== prevProps.location) {
			window.scrollTo(0,0);
		} else if (this.props.location.hash.length > 0) {
			const id = window.location.hash.replace('#', '');

			if (document.getElementById(id)) {
				window.scrollTo(0, document.getElementById(id).offsetTop);
			}

		}
	}

	render() {
		return this.props.children
	}
}

export default withRouter(ScrollToTop);
