import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectText from '../../components/Project/ProjectText';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';

class YellowstoneAnemone extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.yellowstoneAnemone;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Soundscape for the Cities and Memory ‘Yellowstone’ project';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description={this.pageDescription}
					/>

					<ProjectText text={`<p>‘Yellowstone Anemone’ is a soundscape created entirely from a field recording of Anemone Geyser in Yellowstone National Park for the Cities and Memory <a href="https://citiesandmemory.com/yellowstone/" rel="noreferrer noopener" target="_blank">Yellowstone</a> project. The original recording has been dissected into smaller moments, with each section sonically adjusted in a unique way to compliment the sense of propulsion and continuity present.</p>`} />

					<ProjectTrack
						title="Yellowstone Anemone"
						id="603200721"
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.tekN}
						next={projectsData.projects.southAfrica}
					/>
				</article>
			</>
		)
	}
}

export default YellowstoneAnemone;
