import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectText from '../../components/Project/ProjectText';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';

class MexicoColombia extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.mexicoColombia;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Field recordings from Mexico City, Tulum, Bogotá, Cartagena, Cocora Valley and Honda.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="Field recordings from Mexico City, Tulum, Bogotá, Cartagena, Cocora Valley and Honda."
					/>

					<ProjectText
						text={`<p>The recordings were taken between 10th May – 3rd April 2018 and include the sounds of birdsong, taxi journeys in the city, mangroves, busy plazas, mountain descents through the clouds, street musicians, dogs barking in nearby streets, insects and more.</p>`}
					/>

					<ProjectTrack
						title="Mexico and Colombia Field Recordings"
						id="501582666"
						playlist
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.wordpressInstaller}
						next={projectsData.projects.myanmar}
					/>
				</article>
			</>
		)
	}
}

export default MexicoColombia;
