import React, { Component } from 'react';
import './Work.scss';
import ProjectCard from '../ProjectCard/ProjectCard';
import Button from '../Button/Button';
import globalData from '../../json/global';
import projectsData from '../../json/projects';

class Work extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCat: `all`
        };

        this.projectsArr = [];
        this.cards = null;
        this.loadMore = null;
        this.hideClass = 'hide-card';
        this.showClass = 'show-card';
        this.activeClass = 'active';

        this.handleShowAllProjects = this.handleShowAllProjects.bind(this);
    }

    componentDidMount() {

        if (document.getElementById('loadMore')) {
            this.loadMore = document.getElementById('loadMore');
        }

        if (document.getElementsByClassName('project-card')) {
            this.cards = document.getElementsByClassName('project-card');
        }

        for (let project in projectsData.projects) {
            this.projectsArr.push(projectsData.projects[project]);
        }

    }

    handleFilterProjects(cat) {

        this.setState({
            selectedCat: `${cat}`
        });

        setTimeout(() => {

            // remove active class
            Array.from(document.getElementsByClassName('cat-link')).forEach((link) => {
                link.classList.remove(this.activeClass);
            });

            // add active class
            document.querySelector(`.cat-link[href="#${this.state.selectedCat}"]`).classList.add(this.activeClass);

            // hide and show cards based on selected category
            if (this.state.selectedCat === 'all') {
                Array.from(this.cards).forEach((card) => {
                    card.parentElement.classList.remove(this.hideClass);
                    card.parentElement.classList.add(this.showClass);
                });
            } else {
                Array.from(this.cards).forEach((card) => {
                    if (card.getAttribute('data-cat') === this.state.selectedCat) {
                        card.parentElement.classList.remove(this.hideClass);
                        card.parentElement.classList.add(this.showClass);
                    } else {
                        card.parentElement.classList.remove(this.showClass);
                        card.parentElement.classList.add(this.hideClass);
                    }
                });
            }

            // hide `load more` button
            if (this.loadMore) {
                this.loadMore.style.display = 'none';
            }

        }, 100);
    }

    handleShowAllProjects() {

        // show all cards
        Array.from(this.cards).forEach((card) => {
            card.parentElement.classList.add('show-card');
        });

        // hide `load more` button
        if (this.loadMore) {
            this.loadMore.style.display = 'none';
        }

    }

    render() {
        return (
            <div className="work">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h2>Work</h2>
                            <ul className="work__cats">
                                <li><a className="cat-link active"
                                       href="#all"
                                       onClick={this.handleFilterProjects.bind(this, 'all')}
                                       aria-label="Show all">All</a></li>
                                {globalData.categories.map((item) => {
                                    return (
                                        <li key={item.slug}>
                                            <a className="cat-link"
                                               href={`#${item.slug}`}
                                               onClick={this.handleFilterProjects.bind(this, item.slug)}
                                               aria-label={item.title}>{item.title}</a>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="row work__projects">
                        {this.projectsArr.map((item) => {
                            return (
                                <ProjectCard key={item.slug}
                                             slug={item.slug}
                                             title={item.title}
                                             cat={item.cat}
                                             tags={item.tags}
                                             cardType={item.cardType}/>
                            )
                        })}
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-auto mb-10">
                            <Button onClick={this.handleShowAllProjects} className="btn btn--fancy" id="loadMore" type="button" label="Load more" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Work;
