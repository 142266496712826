import React, { Component } from 'react';
import './Project.scss';
import './ProjectTrack.scss';

class ProjectTrack extends Component {

    constructor(props) {
        super(props);

        this.type = 'tracks';

        if (this.props.playlist) {
            this.type = 'playlists';
        }

    }

    render() {
        return (
            <div className="project-track project-content">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                            <div className="project-track__embed">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe className="embed-responsive-item" width="100%" height="20" scrolling="no"
                                            frameBorder="no" allow="autoplay" title={this.props.title}
                                            src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/${this.type}/${this.props.id}&color=%23f3683d&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=true&show_teaser=true&visual=true`}></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProjectTrack;
