import React, { Component } from 'react';
import './Social.scss';
import globalData from '../../json/global';

class Social extends Component {
    render() {
        return (
            <ul className="social" role="menu">
                {globalData.social.map((item) => {
                    return (
                        <li className={item.parentClass} key={item.id} role="menuitem">
                            <a className={item.class}
                               href={item.url}
                               target="_blank"
                               rel="noreferrer noopener"
                               aria-label={item.title}>{item.title}</a>
                        </li>
                    )
                })}
            </ul>
        )
    }
}

export default Social;
