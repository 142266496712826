import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';
import ProjectText from "../../components/Project/ProjectText";

class IllSeeYouAgainFriend extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.illSeeYouAgainFriend;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'A song made during lockdown about missing friends.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="A song made during lockdown about missing friends."
					/>

					<ProjectText
						text={`<p>Includes some field recordings from <a href="https://soundcloud.com/jasewarner/sets/south-africa-field-recordings" rel="noreferrer noopener" target="_blank">South Africa</a> (baboons in the bush, birdsong in Joburg Botanical Gardens, and elephants walking along a riverbank).</p>`}
					/>

					<ProjectTrack
						title="I’ll see you again, friend"
						id="808905946"
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.dawnChorusInFlorence}
						next={projectsData.projects.ross}
					/>
				</article>
			</>
		)
	}
}

export default IllSeeYouAgainFriend;
