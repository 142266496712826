import React, {Component} from 'react';
import './Intro.scss';
import Social from '../Social/Social';
import Two from '../../../node_modules/two.js/build/two';

class Intro extends Component {

	constructor(props) {
		super(props);

		this.state = {
			width: window.innerWidth,
			height: window.innerHeight
		};

		this.two = null;
		this.rectangle = null;
		this.colors = null;
		this.linearGradient = null;
		this.radialGradient = null;
		this.radius = null;
		this.vignette = null;
		this.mouse = null;
		this.destination = null;
		this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		this.updatedWidth = null;
		this.updatedHeight = null;

		this.resize = this.resize.bind(this);

	}

	componentWillMount() {
		let two = new Two({
			type: Two.Types.svg,
			fullscreen: false,
			width: this.state.width,
			height: this.state.height,
			autostart: true
		});
		this.two = two;
	}

	componentDidMount() {
		const two = this.two;
		let rectangle = this.rectangle;
		let colors = this.colors;
		let linearGradient = this.linearGradient;
		let radialGradient = this.radialGradient;
		let radius = this.radius;
		let vignette = this.vignette;
		let mouse = this.mouse;
		let destination = this.destination;
		let isSafari = this.isSafari;
		let updatedWidth = this.updatedWidth;
		let updatedHeight = this.updatedHeight;

		colors = [
			'rgba(23, 29, 54, 1)', // $dark
			'rgba(15, 51, 53, 1)', // $dark-blue-grey
			'rgba(8, 50, 94, 1)', // $marine
			'rgba(26, 19, 14, 1)', // $black
			'rgba(26, 88, 91, 1)', // $dark-green-blue
			'rgba(15, 68, 123, 1)', // $twilight-blue
		];
		colors.index = 0;

		linearGradient = two.makeLinearGradient(
			two.width / 2, -two.height / 2,
			two.width / 2, two.height / 2,
			new Two.Stop(0, colors[0]),
			new Two.Stop(1, colors[1]),
			new Two.Stop(1, colors[2])
		);

		rectangle = two.makeRectangle(two.width / 2, two.height / 2, two.width, two.height);
		rectangle.noStroke();
		rectangle.fill = linearGradient;

		radius = Math.max(two.width, two.height);
		radialGradient = two.makeRadialGradient(
			0, 0,
			radius,
			new Two.Stop(0, 'rgba(205, 66, 23, 0.8)', 0.6),
			new Two.Stop(0.5, 'rgba(205, 66, 23, 0)', 0)
		);

		vignette = two.makeRectangle(two.width / 2, two.height / 2, two.width, two.height);
		vignette.noStroke();
		vignette.fill = radialGradient;

		mouse = new Two.Vector(two.width / 2, two.height / 2);
		destination = new Two.Vector();

		const self = this;

		window.addEventListener('mousemove', function (e) {
			if (!isSafari && two.playing) {
				mouse.set(e.clientX, e.clientY);
				return false;
			}
		});

		window.addEventListener('resize', function () {

			const two = self.two;

			if (two.playing) {

				self.resize();

				two['width'] = self.state.width;
				two['height'] = self.state.height;
				two.renderer.setSize(self.state.width, self.state.height);

				updatedWidth = self.state.width / 2;
				updatedHeight = self.state.height / 2;

				linearGradient.left.y = -updatedHeight;
				linearGradient.right.y = updatedHeight;

				rectangle.vertices[0].set(-updatedWidth, -updatedHeight);
				rectangle.vertices[1].set(updatedWidth, -updatedHeight);
				rectangle.vertices[2].set(updatedWidth, updatedHeight);
				rectangle.vertices[3].set(-updatedWidth, updatedHeight);

				vignette.vertices[0].copy(rectangle.vertices[0]);
				vignette.vertices[1].copy(rectangle.vertices[1]);
				vignette.vertices[2].copy(rectangle.vertices[2]);
				vignette.vertices[3].copy(rectangle.vertices[3]);

				rectangle.translation.set(updatedWidth, updatedHeight);
				vignette.translation.copy(rectangle.translation);

			}

		});

		two.bind('resize', function () {

			const w = two.width / 2;
			const h = two.height / 2;

			linearGradient.left.y = -h;
			linearGradient.right.y = h;

			rectangle.vertices[0].set(-w, -h);
			rectangle.vertices[1].set(w, -h);
			rectangle.vertices[2].set(w, h);
			rectangle.vertices[3].set(-w, h);

			vignette.vertices[0].copy(rectangle.vertices[0]);
			vignette.vertices[1].copy(rectangle.vertices[1]);
			vignette.vertices[2].copy(rectangle.vertices[2]);
			vignette.vertices[3].copy(rectangle.vertices[3]);

			rectangle.translation.set(w, h);
			vignette.translation.copy(rectangle.translation);

			radius = Math.max(two.width, two.height);

		});

		two.bind('update', function (frameCount) {

			radialGradient.radius = (radius / 4) * (Math.sin(frameCount / 60) + 1) / 2 + radius * 0.75;

			destination.set(
				mouse.x - two.width / 2,
				mouse.y - two.height / 2
			);

			radialGradient.center.addSelf(
				destination
					.subSelf(radialGradient.center)
					.multiplyScalar(0.125)
			);

			const o = linearGradient.stops[1].offset;

			if (o < 0.001) {
				linearGradient.stops[1].offset = 1;
				colors.index = (colors.index + 1) % colors.length;
				for (let i = 0; i < linearGradient.stops.length; i++) {
					linearGradient.stops[i].color = colors[(colors.index + i) % colors.length];
				}
				return;
			}

			linearGradient.stops[1].offset -= o * 0.02;

		});

		two.bind('resize', this.resize)
			.appendTo(document.getElementById('introShapes'))
			.trigger('resize')
			.update();
	}

	componentWillUnmount() {
		this.two.pause();
		this.two.unbind('resize', this.resize);
	}

	// componentDidUpdate(prevProps, prevState, snapshot) {
	// 	this.two.update();
	// }

	resize() {
		this.setState({
			width: window.innerWidth,
			height: window.innerHeight
		});
	}

	render() {
		return (
			<div className="intro">
				<div className="intro__shapes" id="introShapes"></div>
				<article className="intro__text">
					<div className="container-fluid">
						<div className="row justify-content-center">
							<div className="col-12">
								<div className="intro__content">
									<h1 className="p">My name is Jase Warner.
										<br/> I’m a freelance web developer <span className="break-xs"></span>based in London.</h1>
									<p>I specialise in developing and designing bespoke Shopify and WordPress themes. In 2023, I was thrilled to win the YunoJuno freelancer of the year award for web development.</p>
									<p>When I’m not writing code and designing, I make ambient and electronic music
										and watch John Carpenter films.</p>
									<p>Scroll down to see some of my work&hellip;</p>
								</div>
							</div>
						</div>
					</div>
				</article>
				<aside className="intro__footer">
					<div className="container-fluid">
						<div className="row justify-content-between">
							<div className="col-auto">
								<Social/>
							</div>
						</div>
					</div>
				</aside>
				<div className="intro__lines">
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		)
	}
}

export default Intro;
