import React, { Component } from 'react';
import './PageHeader.scss';
import renderHTML from 'react-render-html';

class PageHeader extends Component {

    renderTags() {
        if (this.props.tags) {
            return (
                <p>
                    {this.props.tags.map((item) => {
                        return (
                            <span className="tag" key={item}>#{item} </span>
                        )
                    })}
                </p>
            )
        }
    }

    renderDescription() {
        if (this.props.description) {
            return (
                <p className="lead">{renderHTML(this.props.description)}</p>
            )
        }
    }

    render() {
        return (
            <header className="page-header">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-xl-6">
                            <h1>{this.props.title}</h1>
                            {this.renderTags()}
                            {this.renderDescription()}
                        </div>
                    </div>
                </div>
            </header>

        )
    }
}

export default PageHeader;
