import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';

class MarieDreaming extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.marieDreaming;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Marie sleeps, she dreams and the world continues around her';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="Marie sleeps, she dreams and <br/>the world continues around her"
					/>

					<ProjectTrack
						title="Marie, Dreaming"
						id="120350281"
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.thomasHumeau}
						next={projectsData.projects.paris}
					/>
				</article>
			</>
		)
	}
}

export default MarieDreaming;
