import React, { Component } from 'react';
import Intro from "../../components/Intro/Intro";
import Work from "../../components/Work/Work";
import Helmet from 'react-helmet-async';

class Home extends Component {

    constructor(props) {
        super(props);

        this.pageUrl = `https://jase.io/`;
        this.pageTitle = `Jase Warner – Freelance creative front-end developer based in London`;
        this.pageDescription = 'I am a freelance creative front-end developer based in London. I specialise in designing and developing Shopify and WordPress themes.';
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
                <Intro modalState={this.props.modalState}/>
                <Work/>
            </>
        )
    }
}

export default Home;
