import React, { Component } from 'react';
import './Testimonials.scss';
import Slider from 'react-slick';
import globalData from '../../json/global';

class Testimonials extends Component {

    constructor(props) {
        super(props);

        this.settings = {
            arrows: true,
            dots: false,
            infinite: true,
            speed: 800,
            slidesToShow: 2,
            slidesToScroll: 2,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        }

    }

    render() {
        return (
            <div className="testimonials">
                <div className="container-fluid px-0 px-sm-2">
                    <div className="row justify-content-center no-gutters">
                        <div className="col-12 col-sm-10">
                            <h2 className="h3">Testimonials</h2>
                            <Slider {...this.settings}>
                                {globalData.testimonials.map((slide) => {
                                    return (
                                        <div className="testimonials__slide" key={slide.name}>
                                            <article>
                                                <blockquote>&ldquo;{slide.quote}&rdquo;</blockquote>
                                                <cite>{slide.name} <span>{slide.title}</span></cite>
                                            </article>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Testimonials;
