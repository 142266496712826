import React, { Component } from 'react';
import './SkillsTags.scss';

class SkillsTags extends Component {

	constructor(props) {
		super(props);

		this.skills = [
			'Shopify',
			'WordPress',
			'PHP',
			'JavaScript (ES6)',
			'Liquid',
			'Gatsby',
			'React',
			'Gulp',
			'BEM',
			'SCSS',
			'CSS3',
			'HTML5',
			'Bootstrap 5',
			'Git',
			'Figma',
			'Sketch',
		];
	}

	render() {
		return (
			<div className="skills-tags">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-12 col-sm-10 col-lg-8">
							<h2 className="skills-tags__title h3">{this.props.title}</h2>
							<ul className="skills-tags__list">
								{this.skills.map((skill) => {
									return (
										<li key={skill}>{skill}</li>
									)
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default SkillsTags;
