import React, { Component } from 'react';
import './AppleMusicEmbed.scss';

class AppleMusicEmbed extends Component {
	render() {
		return (
			<div className="apple-music-embed">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-12 col-md-10">
							<div className="apple-music-embed__content">
								<iframe allow="autoplay *; encrypted-media *;" frameBorder="0" height="450"
								        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
								        src={this.props.src}
								        title={this.props.title}></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default AppleMusicEmbed;
