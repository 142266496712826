import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';

class Textarea extends Component {

	constructor(props) {
		super(props);
		this.changeValue = this.changeValue.bind(this);
	}

	changeValue(event) {
		this.props.setValue(event.currentTarget.value);
	}

	render() {
		// an error message is returned only if the component is invalid
		const errorMessage = this.props.getErrorMessage();

		return (
			<div className="form-group">
				<label htmlFor={this.props.id}>{this.props.label}</label>
				<textarea onChange={this.changeValue}
				          value={this.props.getValue() || ''}
				          className="form-control"
				          id={this.props.id}
				          rows={this.props.rows}/>
				<span className="error">{errorMessage}</span>
			</div>
		)
	}
}

export default withFormsy(Textarea);
