import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';

class MorningSong extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.morningSong;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Ambient composition created using piano, synths and guitars.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="Ambient composition made using piano, synths and guitars."
					/>

					<ProjectTrack
						title="Morning Song"
						id="528270897"
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.ncfa}
						next={projectsData.projects.bharatJean}
					/>
				</article>
			</>
		)
	}
}

export default MorningSong;
