import React, { Component } from 'react';
import './Footer.scss';
import Social from '../Social/Social';

class Footer extends Component {
    constructor(props) {
        super(props);

        const today = new Date();
        const date = today.getFullYear();

        this.state = {
            date: date
        };
    }

    render() {
        return (
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Social/>
                            <p>&copy; {this.state.date} J. M. Warner Ltd. All Rights Reserved.</p>
                            <p>Website created by me (obvs).</p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;
