import React, { Component } from 'react';
import './ProjectFooter.scss';
import { Link } from 'react-router-dom';

class ProjectFooter extends Component {

	constructor(props) {
		super(props);

		this.prevHtml = () => {
			if (this.props.prev) {
				return (
					<Link to={`./${this.props.prev.slug}`}
					      aria-label={this.props.prev.title}>{this.props.prev.title}
						<span>
							{this.props.prev.tags.map((item) => {
								return (
									<span className="tag" key={item}>#{item} </span>
								)
							})}
						</span>
					</Link>
				)
			}
		};

		this.nextHtml = () => {
			if (this.props.next) {
				return (
					<Link to={`./${this.props.next.slug}`}
					      aria-label={this.props.next.title}>{this.props.next.title}
						<span>
							{this.props.next.tags.map((item) => {
								return (
									<span className="tag" key={item}>#{item} </span>
								)
							})}
						</span>
					</Link>
				)
			}
		}

	}

	render() {
		return (
			<div className="project-footer">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-12 col-md-10">
							<h4 className="project-footer__title">More projects</h4>
							<div className="project-footer__nav">
								{this.prevHtml()}
								{this.nextHtml()}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ProjectFooter;
