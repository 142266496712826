import React, { Component } from 'react';
import '../../assets/scss/_images.scss';
import './ProjectCard.scss';
import { Link } from 'react-router-dom';

class ProjectCard extends Component {

    constructor(props) {
        super(props);

        this.html = null;
        this.cardType = 'jpg';

        if (this.props.cardType) {
            this.cardType = 'video';
        }

        this.img = `/assets/images/projects/${this.props.cat}/${this.props.slug}/jase-warner-${this.props.slug}-01.jpg`;
        this.img2x = `/assets/images/projects/${this.props.cat}/${this.props.slug}/jase-warner-${this.props.slug}-01@2x.jpg`;

        if (this.cardType === 'video') {
            this.html = (
                <div className="project-card__video">
                    <video autoPlay playsInline loop muted controls={false}>
                        <source src={`/assets/videos/${this.props.slug}/jase-warner-${this.props.slug}.mp4`} type="video/mp4"/>
                        <source src={`/assets/videos/${this.props.slug}/jase-warner-${this.props.slug}.ogv`} type="video/ogv"/>
                        Your browser does not support the video tag.
                    </video>
                </div>
            )
        } else {
            this.html = (
                <img className="img-fluid"
                     srcSet={`${this.img} 1x, ${this.img2x} 2x`}
                     src={this.img}
                     alt={this.props.title}
                     width={``}
                     height={``}/>
            )
        }
    }

    render() {
        return (
            <div className="col-12 col-sm-6 col-md-4">
                <Link className="project-card"
                      data-cat={this.props.cat}
                      to={`/${this.props.slug}`}
                      aria-label={this.props.title}>
                    <figure>
                        {this.html}
                    </figure>
                    <div className={`project-card__text`}>
                        <h3>{this.props.title}</h3>
                        <p className="mb-0">
                            {this.props.tags.map((item) => {
                                return (
                                    <span className="tag" key={item}>#{item} </span>
                                )
                            })}
                        </p>
                    </div>
                </Link>
            </div>
        )
    }
}

export default ProjectCard;
