import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';
import ProjectText from "../../components/Project/ProjectText";

class SwimmingWithCicadas extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.swimmingWithCicadas;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'World Listening Day field recording of cicadas in Domazan, France with soundscape';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="World Listening Day field recording of cicadas and accompanying soundscape"
					/>

					<ProjectText
						text={`<p>The field recording was taken by a swimming pool in Domazan in the south of France, where I was kept company by the nearby cicadas.</p>`}
					/>

					<ProjectTrack
						title="Swimming with Cicadas"
						id="215274431"
					/>

					<ProjectTrack
						title="Swimming with Cicadas (Remix)"
						id="215280447"
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.isabelPradilla}
						next={projectsData.projects.aMorningInMay}
					/>
				</article>
			</>
		)
	}
}

export default SwimmingWithCicadas;
