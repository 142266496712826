import React, { Component } from 'react';

class ProjectImages extends Component {
    render() {
        return (
            <div className="project-images project-content">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-6 col-md-5">
                            <figure>
                                <img className="img-fluid mb-3 mb-sm-5"
                                     alt={this.props.firstAlt}
                                     width={this.props.firstWidth}
                                     height={this.props.firstHeight}
                                     srcSet={`${this.props.firstSrc} 1x, ${this.props.firstSrc2x} 2x`}
                                     src={this.props.firstSrc} />
                            </figure>
                        </div>
                        <div className="col-12 col-sm-6 col-md-5">
                            <figure>
                                <img className="img-fluid mb-3 mb-sm-5"
                                     alt={this.props.secondAlt}
                                     width={this.props.secondWidth}
                                     height={this.props.secondHeight}
                                     srcSet={`${this.props.secondSrc} 1x, ${this.props.secondSrc2x} 2x`}
                                     src={this.props.secondSrc} />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProjectImages;
