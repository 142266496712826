import React, { Component } from 'react';

class Button extends Component {
	render() {
		return (
			<button className={this.props.className} id={this.props.id} disabled={this.props.disabled} onClick={this.props.onClick} type={this.props.type}>
				<span className="btn-outer">
					<span className="btn-text">{this.props.label}</span>
				</span>
			</button>
		);
	}
}

export default Button;
