import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './BackHome.scss';

class BackHome extends Component {
	render() {
		return (
			<div className="back-home">
				<Link to='./'>Home</Link>
			</div>
		)
	}
}

export default BackHome;
