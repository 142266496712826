import React, {Component} from 'react';
import PageHeader from '../../components/PageHeader/PageHeader';
import projectsData from '../../json/projects';
import ProjectTrack from '../../components/Project/ProjectTrack';
import ProjectFooter from '../../components/Project/ProjectFooter';
import BackHome from '../../components/BackHome/BackHome';
import Helmet from 'react-helmet-async';
import ProjectText from "../../components/Project/ProjectText";

class Paris extends Component {

	constructor(props) {
		super(props);

		this.project = projectsData.projects.paris;

		this.pageTitle = `${this.project.title} – Jase Warner`;
		this.pageUrl = `https://jase.io/${this.project.slug}`;
		this.pageDescription = 'Following on from my work last summer in Venice and Avignon, I spent a number of days recording the sounds of Paris.';
	}

	render() {
		return (
			<>
				<Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta name="og:title" content={this.pageTitle} />
                    <meta name="og:description" content={this.pageDescription} />
                    <meta name="og:url" content={this.pageUrl} />
                </Helmet>
				<article>
					<PageHeader
						title={this.project.title}
						tags={this.project.tags}
						description="Following on from my work last summer in Venice and Avignon, I spent a number of days recording the sounds of Paris."
					/>

					<ProjectTrack
						title="Paris Field Recordings"
						id="23902476"
						playlist
					/>

					<ProjectText
						text={`<p>After first visiting Palais de Tokyo, where I saw (and heard) the work of Philippe Parreno, I ventured through the city, recording car horns, the fountain outside Le Louvre, general chatter in the streets, La Seine at night, musicians under archways and far-off protests in Le Jardin des Plantes.</p>`}
					/>

					<BackHome/>

					<ProjectFooter
						prev={projectsData.projects.marieDreaming}
						next={projectsData.projects.avignon}
					/>
				</article>
			</>
		)
	}
}

export default Paris;
