import React, { Component } from 'react';
import Helmet from 'react-helmet-async';
import PageHeader from '../../components/PageHeader/PageHeader';
import ProjectText from '../../components/Project/ProjectText';
import ProjectImage from '../../components/Project/ProjectImage';
import BackHome from '../../components/BackHome/BackHome';
import ProjectFooter from '../../components/Project/ProjectFooter';
import projectsData from '../../json/projects';

class NCFA extends Component {

    constructor(props) {
        super(props);

        this.project = projectsData.projects.ncfa;

        this.pageTitle = `${this.project.title} – Jase Warner`;
        this.pageUrl = `https://jase.io/${this.project.slug}`;
        this.pageDescription = 'Bespoke WordPress theme design and development for Natural Capital Finance Alliance';
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.pageTitle}</title>
                    <link rel="canonical" href={this.pageUrl} />
                    <meta name="description" content={this.pageDescription} />
                    <meta property="og:title" content={this.pageTitle} />
                    <meta property="og:description" content={this.pageDescription} />
                    <meta property="og:url" content={this.pageUrl} />
                </Helmet>
                <article>
                    <PageHeader
                        title={this.project.title}
                        tags={this.project.tags}
                        description={`Bespoke WordPress theme design and development`}
                    />

                    <ProjectText
                        text={`<p>I was hired by <a href="https://naturalcapital.finance/" target="_blank" rel="noopener noreferrer">Natural Capital Finance Alliance</a> to design and develop a bespoke WordPress theme for their new website. NCFA works with the UN Environment Finance Initiative to provide advice and tools to reduce and manage the risks of pollution and climate change.</p>`}
                    />

                    <ProjectImage
                        src={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02.jpg`}
                        src2x={`/assets/images/projects/${this.project.cat}/${this.project.slug}/jase-warner-${this.project.slug}-02@2x.jpg`}
                    />

                    <BackHome/>

                    <ProjectFooter
                        prev={projectsData.projects.homunculus}
                        next={projectsData.projects.morningSong}
                    />
                </article>
            </>
        )
    }
}

export default NCFA;
