import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import ScrollProgressBar from '../../components/ScrollProgressBar/ScrollProgressBar';
import Header from '../../components/Header/Header';
import Container from '../../components/Container/Container';
import Nav from '../../components/Nav/Nav';
import Toolbar from '../Toolbar/Toolbar';
import Player from '../Player/Player';
import ContactModal from '../ContactModal/ContactModal';
import LoadingScreen from '../LoadingScreen/LoadingScreen';

ReactGA.initialize('G-MGVEW1WJ6S');
const history = createBrowserHistory();
history.listen(location => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send(location.pathname);
});

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            navIsOpen: false,
            playerIsOpen: false,
            modalIsOpen: false,
            hidingLoadingScreen: false,
            hideLoadingScreen: false,
        };

        this.helmetContext = {};
        this.body = null;

        this.toggleNav = this.toggleNav.bind(this);
        this.togglePlayer = this.togglePlayer.bind(this);
        this.handleShowModal = this.handleShowModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    componentDidMount() {

        if (document.getElementById('body')) {
            this.body = document.getElementById('body');
        }

        // hide loading screen
        const delay = 2000;
        const step = 500;

        setTimeout(() => {
            this.setState({
                hidingLoadingScreen: true
            });
        }, delay);

        setTimeout(() => {
            this.setState({
                hideLoadingScreen: true
            });
        }, delay + step);

        const tidioScript = document.createElement('script');

        tidioScript.src = '//code.tidio.co/0gfrzxlrnjfyiagsit0cejyg11ypr3k7.js';
        tidioScript.async = true;

        document.body.appendChild(tidioScript);

    }

    toggleNav() {
        this.setState({
            navIsOpen: !this.state.navIsOpen
        });

        if (!this.state.navIsOpen) {
            this.body.classList.add('nav-open')
        } else {
            this.body.classList.remove('nav-open')
        }
    }

    togglePlayer() {

        if (window.innerWidth < 576) {
            // soundcloud embed cookie notice obscures play buttons in mobile!
            window.open('https://soundcloud.com/jasewarner', '_blank');
        } else {
            this.setState( {
                playerIsOpen: !this.state.playerIsOpen
            });

            if (!this.state.playerIsOpen) {
                this.body.classList.add('player-open')
            } else {
                this.body.classList.remove('player-open')
            }
        }

    }

    handleShowModal() {
        this.setState({
            modalIsOpen: true
        });
    }

    handleCloseModal() {
        this.setState({
            modalIsOpen: false
        });
    }

    render() {
        return (
            <HelmetProvider context={this.helmetContext}>
                <Router>
                    <ScrollToTop>
                        <ScrollProgressBar/>
                        <Header toggleNav={this.toggleNav}/>
                        <Nav toggleNav={this.toggleNav} handleShowModal={this.handleShowModal}/>
                        <Container/>
                        <Toolbar togglePlayer={this.togglePlayer} handleShowModal={this.handleShowModal}/>
                        <Player togglePlayer={this.togglePlayer}/>
                        <ContactModal show={this.state.modalIsOpen} handleCloseModal={this.handleCloseModal}/>
                        <LoadingScreen hidingLoadingScreen={this.state.hidingLoadingScreen} hideLoadingScreen={this.state.hideLoadingScreen}/>
                    </ScrollToTop>
                </Router>
            </HelmetProvider>
        );
    }
}

export default App;
