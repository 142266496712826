import React, { Component } from 'react';
import ScrollProgress from 'scrollprogress';
import './ScrollProgressBar.scss';

class ScrollProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: 0
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.progressObserver = new ScrollProgress((x, y) => {
                this.setState({ progress: y * 100 });
            });
        }, 4000);
    }

    componentWillUnmount() {
        this.progressObserver.destroy();
    }

    render() {
        const style = {
            width: `${this.state.progress}%`
        };

        return (
            <div className="scroll-progress-bar">
                <div className="scroll-progress-bar__inner" style={ style }/>
            </div>
        )
    }
}

export default ScrollProgressBar;
